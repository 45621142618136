import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
} from '@mui/material'

import { IconButton } from '@mui/material'
import EditNoteIcon from '@mui/icons-material/EditNote'
import { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'
import useSharedSelectedBrfItemState from './sharedState'
import EditBRF from '../../components/brf/EditBRF'
import SearchBrf from '../../components/search/SearchBrf'
import { getToken } from '../../components/authentication/authService'
import { useMediaQuery, useTheme } from '@mui/material'

export default function BrfContent() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isFold = useMediaQuery('(max-width: 904px)') // Adjusted for Z Fold 5's inner screen

  const token = getToken()
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [total, setTotal] = useState(0)

  // State for dialog control and row details
  const [open, setOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useSharedSelectedBrfItemState()
  const [isEditMode, setIsEditMode] = useState(false)
  const [editableFields, setEditableFields] = useState({})
  const [updateCounter, setUpdateCounter] = useState(0) // state to trigger table row updates when editing and saving brf

  // Search box
  const [searchQuery, setSearchQuery] = useState('')
  const [error, setError] = useState(null)

  // fetch names api
  const [names, setNames] = useState([])
  const [inputValue, setInputValue] = useState('')

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const mobileColumns = [
    {
      field: 'name',
      headerName: 'Namn',
      width: 150,
    },
    {
      field: 'postal',
      headerName: 'Post',
      width: 105,
    },
    {
      field: 'kpi',
      headerName: 'KPI',
      type: 'boolean',
      width: 90,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" width="100%">
          <IconButton
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => handleEditView(params.row)}
          >
            <EditNoteIcon />
          </IconButton>
        </Box>
      ),
    },
  ]

  // Define hardcoded columns
  const columns = [
    {
      field: 'name',
      headerName: 'Namn',
      width: 250,
    },
    {
      field: 'postal',
      headerName: 'Post',
      width: 200,
    },
    {
      field: 'year_of_construction',
      headerName: 'Byggår',
      width: 150,
    },
    {
      field: 'number_of_apartments',
      headerName: 'Antal bostadsrätter',
      width: 150,
    },
    {
      field: 'land_ownership',
      headerName: 'Markägande',
      width: 100,
    },
    {
      field: 'kpi',
      headerName: 'KPI',
      type: 'boolean',
      width: 100,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <IconButton
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => handleEditView(params.row)}
          >
            <EditNoteIcon />
          </IconButton>
        </Box>
      ),
    },
  ]

  const brfbo_app_url = process.env.REACT_APP_BRFBO_BASE_URL

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `${brfbo_app_url}/brf?page=${page + 1}&size=${pageSize}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const result = await response.json()
      setRows(result.content) // Set rows from the 'content' array in the response
      setTotal(result.totalElements || result.content.length) // Set total count for pagination
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchSearchData = async (inputValue, page, pageSize) => {
    if (inputValue) {
      setLoading(true)
      const actualPage = inputValue ? page : page + 1
      const actualPageSize = inputValue === '' ? 20 : pageSize
      const eventSource = new EventSource(
        `${brfbo_app_url}/stream/brf?q=${encodeURIComponent(inputValue)}&page=${actualPage}&size=${actualPageSize}`
      )

      eventSource.onopen = () => {
        console.log('SSE connection established.')
        setError(null) // Clear any previous errors
      }

      eventSource.onmessage = (event) => {
        try {
          const result = JSON.parse(event.data)

          // Check if the result contains an error message
          if (typeof result === 'string' && result.startsWith('Error:')) {
            console.error(result) // Log the error message
            setError(result) // Set the error message in state for display
            setLoading(false) // Stop loading
            eventSource.close() // Close the SSE connection
            return
          }

          // No error - process the result normally
          console.log(result)
          setNames(result.content)
          setRows(result.content)
          setTotal(result.totalElements || result.content.length)
          setError(null) // Clear any previous errors
        } catch (e) {
          console.error('Error parsing SSE data:', e)
          setError('Error parsing SSE data') // Set a generic error message
        } finally {
          setLoading(false)
        }
      }

      eventSource.onerror = (error) => {
        // Check if the connection is permanently closed
        if (eventSource.readyState === EventSource.CLOSED) {
          console.error('SSE connection closed.')
          setError('SSE connection closed unexpectedly.')
        } else {
          console.warn('Temporary SSE connection error:', error)
        }
        setLoading(false)
        eventSource.close()
      }

      return () => {
        eventSource.close()
      }
    }
  }

  // Wrap fetchData in useCallback with debouncing
  const debouncedFetchData = useCallback(
    debounce((searchQuery) => fetchSearchData(searchQuery, page, pageSize), 500),
    [page, pageSize]
  )

  // Update debounced search on inputValue change
  useEffect(() => {
    if (inputValue.trim()) {
      debouncedFetchData(inputValue)
    }
  }, [inputValue, debouncedFetchData])

  useEffect(() => {
    if (!inputValue) {
      fetchData()
    }
  }, [inputValue, updateCounter, page, pageSize])

  const handleBrfChange = (event, selectedValue) => {
    setSearchQuery(selectedValue)
  }

  const handleInputValueChange = (event, newInputValue) => {
    setInputValue(newInputValue)
  }

  // Handlers for View and Edit actions
  const handleEditView = async (row) => {
    try {
      // Set loading to true if you want to show a loader in the dialog
      setLoading(true)

      // Make an API call to fetch details using the BRF name or any other identifier
      const response = await fetch(`${brfbo_app_url}/brf/${row.name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = await response.json()

      // Update state with fetched data
      setSelectedRow(data)
      setEditableFields(data) // Initialize fields to data fetched from API
      setIsEditMode(false)
      setOpen(true)
    } catch (error) {
      console.error('Error fetching BRF details:', error)
    } finally {
      setLoading(false) // Stop loading after fetching data
    }
  }

  const handleEditToggle = () => {
    setIsEditMode((prev) => !prev)
  }

  // Handle field changes, supporting nested KPI fields
  const handleFieldChange = (fieldKey, newValue) => {
    setEditableFields((prev) => {
      // Handle nested keys for "kpi" fields
      if (Array.isArray(fieldKey)) {
        const [key, index, subKey] = fieldKey
        if (key === 'kpi' && typeof index === 'number' && subKey) {
          // Create a shallow copy of "kpi" array
          const updatedKpi = [...(prev.kpi || [])]

          // Ensure "kpi" entry exists at this index
          updatedKpi[index] = { ...updatedKpi[index], [subKey]: newValue }

          return { ...prev, kpi: updatedKpi }
        }
      }

      // Handle regular (non-nested) fields
      return { ...prev, [fieldKey]: newValue }
    })
  }

  // Handle saving the edited fields
  const handleSave = async () => {
    try {
      setLoading(true)
      // Send the updated data to the backend API
      const response = await fetch(`${brfbo_app_url}/brf`, {
        method: 'PUT', // or 'PATCH' depending on your API
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editableFields),
      })
      if (!response.ok) {
        throw new Error('Failed to update BRF details')
      }
      const updatedData = await response.json()
      // Update the rows with the updated data
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.name === updatedData.name ? updatedData : row
        )
      )
      setSelectedRow(updatedData)
      setIsEditMode(false)
      setOpen(false)
      setSnackbarOpen(true)
      setUpdateCounter((prev) => prev + 1)
    } catch (error) {
      console.error('Error saving BRF details:', error)
      // Optionally, show an error message to the user
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setIsEditMode(false)
    setSelectedRow(null)
  }

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
    setOpen(false)
  }

  return (
    <>
      {/* Snackbar for success notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        severity="success"
        variant="filled"
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          BRF has been edited successfully!
        </Alert>
      </Snackbar>
      <SearchBrf
        names={names}
        value={searchQuery}
        inputValue={inputValue}
        onChange={handleBrfChange}
        onInputChange={handleInputValueChange}
      />
      <Box
        sx={{
          py: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
          height: 980,
        }}
      >
        <DataGrid
          rows={rows}
          columns={isMobile || isFold ? mobileColumns : columns}
          rowCount={total}
          loading={loading}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'skeleton',
            },
          }}
          pageSize={pageSize}
          //autoPageSize
          pagination
          paginationMode="server"
          onPaginationModelChange={(model) => {
            setPage(model.page)
            if (model.pageSize > 0) setPageSize(model.pageSize) // Only update if pageSize is > 0
          }}
          getRowId={(row) => row.id} // Use 'id' as the unique identifier for each row
          //checkboxSelection
        />
      </Box>
      <Box>
        {/* DataGrid and other components remain as before */}

        {/* Dialog for detailed view/edit */}
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>
            {selectedRow ? selectedRow.name : 'BRF Details'}
          </DialogTitle>
          <DialogContent>
            {selectedRow ? (
              <EditBRF
                data={editableFields}
                isEditMode={isEditMode}
                onChange={handleFieldChange}
              />
            ) : (
              <DialogContentText>No details available.</DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            {isEditMode ? (
              <>
                <Button onClick={handleSave} color="primary">
                  Save
                </Button>
                <Button onClick={handleEditToggle} color="secondary">
                  Cancel
                </Button>
              </>
            ) : (
              <Button onClick={handleEditToggle} color="primary">
                Edit
              </Button>
            )}
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}
