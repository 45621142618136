import {BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Dashboard from './pages/dashboard/Dashboard'
import LoginPage from './pages/LoginPage'
import { isAuthenticated } from './components/authentication/authService';

function PrivateRoute({ children }) {
  return isAuthenticated() ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path='dashboard/*' element={
          <PrivateRoute>
            <Dashboard/>
          </PrivateRoute>
          } 
          />
        <Route path='*' element={<Navigate to="/login" />}  />
      </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
