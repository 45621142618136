import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Snackbar,
  Alert,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'

import { useState } from 'react'
import { getToken } from '../authentication/authService'

// Configuration array for each field
const brfFieldConfig = [
  { label: 'Namn', name: 'name', type: 'text', required: true },
  { label: 'Adress', name: 'address', type: 'text' },
  { label: 'Post', name: 'postal', type: 'text', required: true },
  {
    label: 'Byggår',
    name: 'year_of_construction',
    type: 'number',
    required: true,
  },
  {
    label: 'Antal bostadsrätter',
    name: 'number_of_apartments',
    type: 'number',
    required: true,
  },
  { label: 'Markägande', name: 'land_ownership', type: 'text', required: true },
]

const kpiFieldConfig = [
  { label: 'År', name: 'financial_year', type: 'text' },
  { label: 'Skuldsättning per kvm', name: 'debt_per_sqm', type: 'number' },
  {
    label: 'Skuldsättning per kvm upplåten med bostadsrätt',
    name: 'cooperative_housing_association_debt_per_sqm',
    type: 'number',
  },
  {
    label: 'Årsavgiften per kvm upplåten med bostadsrätt',
    name: 'annual_fee_per_sqm',
    type: 'number',
  },
  {
    label: 'Årsavgifternas andel i % av totala rörelseintäkter',
    name: 'annual_fee_per_total_income_percent',
    type: 'number',
  },
  {
    label: 'Energikostnad per kvm',
    name: 'energy_cost_per_sqm',
    type: 'number',
  },
  { label: 'Sparande per kvm', name: 'savings_per_sqm', type: 'number' },
  {
    label: 'Räntekänslighet (%)',
    name: 'interest_rate_sensitivity',
    type: 'number',
  },
  { label: 'Kassaflöde', name: 'cash_flow', type: 'number' },
]

function AddBrfDialog({isMobile}) {
  const token = getToken()
  const [open, setOpen] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false) // State for Snackbar
  const [errors, setErrors] = useState({})
  const [brfData, setBrfData] = useState(
    brfFieldConfig.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {})
  )
  const [kpiData, setKpiData] = useState(
    kpiFieldConfig.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {})
  )

  const brfbo_app_url = process.env.REACT_APP_BRFBO_BASE_URL

  // Validation function
  const validate = () => {
    const newErrors = {}
    brfFieldConfig.forEach((field) => {
      if (field.required && !brfData[field.name].trim()) {
        newErrors[field.name] = `${field.label} is required.`
      }
    })
    return newErrors
  }

  // Handle input changes for both BRF and KPI fields
  const handleChange = (e, isKpiField = false) => {
    const { name, value } = e.target
    if (isKpiField) {
      setKpiData((prevData) => ({ ...prevData, [name]: value }))
      if (errors[name] && value) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
      }
    } else {
      setBrfData((prevData) => ({ ...prevData, [name]: value }))
      if (errors[name] && value) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
      }
    }
  }

  // Handle form submission
  const handleSubmit = async () => {
    const validationErrors = validate()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }
    try {
      const requestBody = JSON.stringify({ ...brfData, kpi: [kpiData] })
      const response = await fetch(`${brfbo_app_url}/brf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: requestBody,
      })
      if (response.ok) {
        console.log(response.json())
        setOpen(false)
        setErrors({})
        setBrfData({})
        setKpiData({})
        setSnackbarOpen(true) // Show Snackbar on successful addition
      } else {
        console.error('Failed to add BRF')
        setErrors({ 'Error sending request to backend': response })
      }
    } catch (error) {
      console.error('Error adding BRF:', error)
      setErrors({ 'Error sending request to backend': error })
    }
  }

  const onClose = () => {
    setOpen(false)
    setErrors({})
    setBrfData({})
    setKpiData({})
  }

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
    setOpen(false)
  }

  return (
    <>
      {/* Snackbar for success notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        severity="success"
        variant="filled"
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          BRF added successfully!
        </Alert>
      </Snackbar>
      <Button
        startIcon={<AddCircleIcon />}
        onClick={() => setOpen(true)}
        aria-label="add brf"
        sx={{ padding: '4px' }}
        color="secondary"
      >
        {isMobile ? 'Add' : 'Add BRF'}
      </Button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Add New BRF</DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              {brfFieldConfig.map((field) => (
                <TableRow key={field.name}>
                  <TableCell>
                    <strong>{field.label}</strong>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={field.name}
                      type={field.type}
                      value={brfData[field.name]}
                      onChange={handleChange}
                      required={field.required || false}
                      error={Boolean(errors[field.name])}
                      helperText={errors[field.name] || ''}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {/* Render KPI fields */}
              <TableRow>
                <TableCell colSpan={2}>
                  <strong>KPI Data</strong>
                </TableCell>
              </TableRow>
              {kpiFieldConfig.map((field) => (
                <TableRow key={field.name}>
                  <TableCell>{field.label}</TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={field.name}
                      type={field.type}
                      value={kpiData[field.name]}
                      onChange={(e) => handleChange(e, true)}
                      error={Boolean(errors[field.name])}
                      helperText={errors[field.name] || ''}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddBrfDialog
