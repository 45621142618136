import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Analytics from './Analytics';
import BRFMenu from './BRFMenu';

function SideList({ pathname }) {
  return (
    <Box
      sx={{
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {/* Content Display */}
      {pathname.startsWith('/dashboard') && <Analytics/>}
      {pathname.startsWith('/brf') && <BRFMenu />}
    </Box>
  );
}

SideList.propTypes = {
  pathname: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
};

export default SideList;
