const brfbo_app_url = process.env.REACT_APP_BRFBO_BASE_URL

// authService.js
export const login = async (username, password) => {
    const response = await fetch(`${brfbo_app_url}/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });
  
    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('token', data.token); // Store token in local storage
      return true;
    } else {
      throw new Error('Login failed');
    }
  };
  
  export const logout = () => {
    localStorage.removeItem('token');
  };
  
  export const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;
  
    // Decode token and check expiration (or check on backend)
    const payload = JSON.parse(atob(token.split('.')[1]));
    const isExpired = Date.now() >= payload.exp * 1000;
    if (isExpired) {
      logout();
      return false;
    }
    return true;
  };
  
  export const brfboAdminSession = () => {
    if(isAuthenticated()) {
        return {
            user: {
                name: 'BrfBo Admin',
                email: 'brfbo-app-admin@brfbo.se',
                image: 'https://avatars.githubusercontent.com/u/3271540?s=400&u=89becad8c7f01de072b981f82aaeafbd520dabb5&v=4',
            }
        }
    } else {
        return null
    }
  }

  export const getToken = () => {
    return localStorage.getItem('token');
  }