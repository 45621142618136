import { useBetween } from 'use-between'
import { useState } from 'react'

// Make a custom hook with your future shared state
const useBrfItemState = () => {
  const [selectedRow, setSelectedRow] = useState('')
  return [selectedRow, setSelectedRow]
}

// Make a custom hook for sharing your form state between any components
const useSharedSelectedBrfItemState = () => useBetween(useBrfItemState)

export default useSharedSelectedBrfItemState