import { TableRow, TableCell, TextField } from '@mui/material'
import PropTypes from 'prop-types'

function EditableFieldTable({ label, value, isEditMode, fieldKey, onChange }) {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <strong>{label}</strong>
      </TableCell>
      <TableCell>
        {isEditMode ? (
          <TextField
            value={value ?? ""} // Use an empty string if value is null or undefined
            onChange={(e) => onChange(fieldKey, e.target.value)}
            fullWidth
          />
        ) : (
          value !== null && value !== undefined ? value : 'N/A' // Show 'N/A' if value is null or undefined
        )}
      </TableCell>
    </TableRow>
  )
}

EditableFieldTable.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditMode: PropTypes.bool.isRequired,
  fieldKey: PropTypes.array.isRequired, // Updated to accept an array for nested paths
  onChange: PropTypes.func.isRequired,
};

export default EditableFieldTable;
