import * as React from 'react';

import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { useDemoRouter } from '@toolpad/core/internal';
import SideList from './SideList';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { isAuthenticated, logout, brfboAdminSession, login } from '../../components/authentication/authService';

const NAVIGATION = [
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    segment: 'brf',
    title: 'BRFs',
    icon: <ApartmentIcon />,
    pattern: 'brfs{/:brfId}*',
  },
]

const dashboardTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 905,
      lg: 1200,
      xl: 1536,
    },
  },
})


function Dashboard() {
  const navigate = useNavigate()
  useEffect(() => {
    if(!isAuthenticated()) {
      navigate("/login")
    }
  }, [navigate])

  const [session, setSession] = useState(brfboAdminSession);

  const authentication = React.useMemo(() => {
    return {
      signIn: () => {
        setSession(brfboAdminSession);
      },
      signOut: () => {
        logout()
        setSession(null);
        navigate('/login'); // Redirect to the home page after successful login
      },
    };
  }, []);

  const router = useDemoRouter('/brfs');

  return (
    <AppProvider
      session={session}
      authentication={authentication}
      navigation={NAVIGATION}
      router={router}
      theme={dashboardTheme}
      branding={{
        title: "BRF Bo Admin UI"
      }}
    >
      <DashboardLayout>
        {/* Pass pathname and navigate as props to SideList */}
        <SideList pathname={router.pathname}/>
      </DashboardLayout>
    </AppProvider>
  );
}

export default Dashboard;
