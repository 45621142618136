import EditableFieldTable from './EditableField'
import { DialogContentText, Table, TableBody } from '@mui/material'

  // Define a list of fields with labels and keys
  const brfBasicInfo = [
    { label: 'Namn', fieldKey: ['name'] },
    { label: 'Post', fieldKey: ['postal'] },
    { label: 'Adress', fieldKey: ['address'] },
    {
      label: 'Byggår',
      fieldKey: ['year_of_construction'],
    },
    {
      label: 'Antal bostadsrätter',
      fieldKey: ['number_of_apartments'],
    },
    {
      label: 'Markägande',
      fieldKey: ['land_ownership'],
    },
  ]

  const brfKPI = [
    { label: 'År', fieldKey: ['kpi', 0, 'financial_year'] },
    { label: 'Skuldsättning per kvm', fieldKey: ['kpi', 0, 'debt_per_sqm'] },
    {
      label: 'Skuldsättning per kvm upplåten med bostadsrätt',
      fieldKey: ['kpi', 0, 'cooperative_housing_association_debt_per_sqm'],
    },
    {
      label: 'Årsavgiften per kvm upplåten med bostadsrätt',
      fieldKey: ['kpi', 0, 'annual_fee_per_sqm'],
    },
    {
      label: 'Årsavgifternas andel i % av totala rörelseintäkter',
      fieldKey: ['kpi', 0, 'annual_fee_per_total_income_percent'],
    },
    {
      label: 'Energikostnad per kvm',
      fieldKey: ['kpi', 0, 'energy_cost_per_sqm'],
    },
    { label: 'Sparande per kvm', fieldKey: ['kpi', 0, 'savings_per_sqm'] },
    {
      label: 'Räntekänslighet (%)',
      fieldKey: ['kpi', 0, 'interest_rate_sensitivity'],
    },
    { label: 'Kassaflöde', fieldKey: ['kpi', 0, 'cash_flow'] },
    //{ label: 'Solidity', fieldKey: ['kpi', 0, 'solidity'] },
    //{ label: 'Solvency', fieldKey: ['kpi', 0, 'solvency'] },
    //{ label: 'Liquidity', fieldKey: ['kpi', 0, 'liquidity'] },
    //{
    //  label: 'Rental Income per sqm',
    //  fieldKey: ['kpi', 0, 'rental_income_per_sqm'],
    //},
  ]
  
function EditBRF({ data, isEditMode, onChange }) {

  const getValueByPath = (obj, path) => {
    return path.reduce((acc, key) => (acc ? acc[key] : 'N/A'), obj)
  }

  return (
    <>
      <Table>
        <TableBody>
          {brfBasicInfo.map(({ label, fieldKey }) => (
            <EditableFieldTable
              key={fieldKey.join('.')}
              label={label}
              value={getValueByPath(data, fieldKey)}
              isEditMode={isEditMode}
              fieldKey={fieldKey}
              onChange={onChange}
            />
          ))}
        </TableBody>
      </Table>

      <DialogContentText sx={{ mt: 2, mb: 1 }}>
        <strong>KPI:</strong>
      </DialogContentText>

      <Table>
        <TableBody>
          {brfKPI.map(({ label, fieldKey }) => (
            <EditableFieldTable
              key={fieldKey.join('.')}
              label={label}
              value={getValueByPath(data, fieldKey)}
              isEditMode={isEditMode}
              fieldKey={fieldKey}
              onChange={onChange}
            />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default EditBRF
