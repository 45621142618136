import {
  Box,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useState } from 'react'
import Ajv from 'ajv'
import { getToken } from '../authentication/authService'

function UploadJSON() {
  const ajv = new Ajv()

  const brfSchema = {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        name: { type: 'string' },
        municipality: { type: ['string', 'null'] },
        address: { type: ['string', 'null'] },
        postal: { type: 'string' },
        organization_number: { type: ['string', 'null'] },
        year_of_construction: { type: 'string' },
        number_of_apartments: { type: 'integer' },
        land_ownership: { type: 'string' },
        kpi: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              debt_per_sqm: { type: 'number' },
              cooperative_housing_association_debt_per_sqm: { type: 'number' },
              annual_fee_per_sqm: { type: 'number' },
              annual_fee_per_total_income_percent: { type: 'number' },
              rental_income_per_sqm: { type: 'number' },
              energy_cost_per_sqm: { type: 'number' },
              savings_per_sqm: { type: 'number' },
              interest_rate_sensitivity: { type: 'number' },
              cash_flow: { type: 'number' },
              solidity: { type: 'number' },
              solvency: { type: 'string' },
              liquidity: { type: 'number' },
              financial_year: { type: 'string' },
            },
          },
        },
      },
      required: [
        'name',
        'postal',
        'year_of_construction',
        'number_of_apartments',
        'land_ownership',
      ], // Add 'name' to required
    },
  }

  const validate = ajv.compile(brfSchema)

  const validateJSON = (data) => {
    const valid = validate(data)
    if (!valid) {
      return validate.errors // Return the validation errors
    }
    return null // No errors
  }

  const token = getToken()
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null)
  const [fileContent, setFileContent] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false) // State for Snackbar

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file && file.type === 'application/json') {
      const reader = new FileReader()
      reader.onload = (e) => {
        try {
          const jsonContent = JSON.parse(e.target.result)
          const validationError = validateJSON(jsonContent)
          console.log(validationError)
          if (validationError) {
            setError(validationError) // Set error message if validation fails
            return // Exit the function if there's an error
          }
          setFileContent(jsonContent) // Store parsed JSON content
        } catch (error) {
          console.error('Invalid JSON file:', error)
          alert('Invalid JSON format.')
        }
      }
      reader.readAsText(file)
    } else {
      alert('Please select a JSON file.')
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setError(null)
  }
  const brfbo_app_url = process.env.REACT_APP_BRFBO_BASE_URL

  // Handle file upload using fetch
  const handleUpload = async () => {
    if (fileContent) {
      try {
        const response = await fetch(`${brfbo_app_url}/brf/_upload`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(fileContent),
        })

        if (response.ok) {
          setError(null) // Clear any previous error messages
          setFileContent(null)
          setOpen(false)
          setSnackbarOpen(true)
        } else {
          setError([{ message: 'An error occurred when uploading the BRF collection json file' }])
        }
      } catch (error) {
        console.error('Error uploading file:', error)
        setError('Upload failed.')
      }
    } else {
      setError('Failed to upload json data.')
    }
  }

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
    setOpen(false)
    setError(null)
  }

  return (
    <>
      {/* Snackbar for success notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        severity="success"
        variant="filled"
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          BRF Collections is uploaded successfully!
        </Alert>
      </Snackbar>
      {/* Icon button to open dialog */}
      <Button
        startIcon={<UploadFileIcon />}
        onClick={() => setOpen(true)}
        aria-label="upload file"
        sx={{ padding: '4px', margin: '1px' }}
      >
        Upload
      </Button>

      {/* Dialog for file upload */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Upload BRF Collection Json File</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <input
              type="file"
              accept="application/json"
              onChange={handleFileChange}
              style={{
                display: 'block',
                padding: '8px 0',
                width: '100%',
                cursor: 'pointer',
              }}
            />
          </Box>

          {/* Display error messages */}
          {error && (
            <Box sx={{ mb: 2 }}>
              {error.map((err, index) => (
                <Alert key={index} severity="error">
                  {err.message}
                </Alert>
              ))}
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={!fileContent}
            fullWidth
          >
            Upload
          </Button>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UploadJSON
