import { createTheme, useTheme } from '@mui/material'
import { login } from '../components/authentication/authService';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AppProvider } from '@toolpad/core/AppProvider';
import { SignInPage } from '@toolpad/core/SignInPage';

// preview-start
const providers = [{ id: 'credentials', name: 'Email and Password' }];
// preview-end

const signIn = async (provider, formData) => {
  const username = formData.get('email');
  const password = formData.get('password');
  console.log(`Signing in with "${provider.name}" and credentials: ${username}, ${password}`);

  // Wrap in promise and wait for login function to complete
  const promise = new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        await login(username, password);
        resolve(); // Indicate success
      } catch (error) {
        console.error("Login failed:", error);
        reject(error); // Indicate failure
      }
    }, 300);
  });

  return promise;
};

const loginTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
})

function CustomEmailField() {
  return (
    <TextField
      id="input-with-icon-textfield"
      label="Email"
      name="email"
      type="email"
      size="small"
      variant="outlined"
      required
      fullWidth
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircle fontSize="inherit" />
            </InputAdornment>
          ),
        },
      }}
    />
  );
}

function CustomPasswordField() {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl sx={{ my: 2 }} fullWidth variant="outlined">
      <InputLabel size="small" htmlFor="outlined-adornment-password">
        Password
      </InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        name="password"
        size="small"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="small"
            >
              {showPassword ? (
                <VisibilityOff fontSize="inherit" />
              ) : (
                <Visibility fontSize="inherit" />
              )}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />
    </FormControl>
  );
}

function CustomButton() {
  return (
    <Button
      type="submit"
      variant="outlined"
      color="info"
      size="small"
      disableElevation
      fullWidth
      sx={{ my: 2 }}
    >
      Sign In
    </Button>
  );
}

function LoginPage() {
  const navigate = useNavigate();

  const handleSignIn = async (provider, formData) => {
    try {
      await signIn(provider, formData);
      navigate('/dashboard'); // Redirect to the home page after successful login
    } catch (error) {
      console.error("Failed to sign in:", error);
      // Optional: handle login failure (e.g., show an error message)
    }
  };

  return (
    <>
    <AppProvider theme={loginTheme}>
      <SignInPage 
          signIn={handleSignIn} 
          providers={providers} 
          slots={{
            emailField: CustomEmailField,
            passwordField: CustomPasswordField,
            submitButton: CustomButton,
          }}
      />
    </AppProvider>
    </>
  )
}

export default LoginPage;
