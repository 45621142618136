import Box from '@mui/material/Box';

function Analytics() {
  return (
    <>
        <Box>
            <p>hello analytics</p>
        </Box>
    </>
  )
}

export default Analytics
