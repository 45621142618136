import React from 'react'
import BrfContent from './BrfContent'

function BRFMenu() {
  return (
      <BrfContent></BrfContent>
  )
}

export default BRFMenu
