import React from 'react'
import Box from '@mui/material/Box'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { TextField, Button, Stack } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import UploadJSON from '../upload/UploadJson'
import AddBrfDialog from '../brf/AddBRFDialog'
import { useMediaQuery, useTheme } from '@mui/material'

function SearchBrf({ names, value, inputValue, onChange, onInputChange }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isFold = useMediaQuery('(max-width: 904px)') // Adjusted for Z Fold 5's inner screen

  const OPTIONS_LIMIT = 5
  const defaultFilterOptions = createFilterOptions()
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT)
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={isMobile ? { width: '100%' } : {}}>
        <Stack direction="row" spacing={3} alignItems="inherit">
          <Autocomplete
            freeSolo
            id="search-field-brfbo"
            disableClearable
            filterOptions={filterOptions}
            options={(Array.isArray(names) ? names : []).map(
              (option) => option.name
            )}
            value={value}
            inputValue={inputValue}
            onChange={onChange}
            onInputChange={onInputChange}
            renderInput={(params) => (
              <Box width={isMobile || isFold ? '300px' : '580px'}>
                <TextField
                  {...params}
                  label="Search BRF"
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      type: 'search',
                      startAdornment: (
                        <InputAdornment position="start">
                          <ManageSearchIcon color="primary" />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Box>
            )}
          />
          {isMobile || isFold ? (
            <>
              <AddBrfDialog isMobile={isMobile} />
            </>
          ) : (
            <>
              <Box width="115px"></Box>
              <UploadJSON isMobile={isMobile} />
              <AddBrfDialog isMobile={isMobile} />
            </>
          )}
        </Stack>
      </Box>
    </div>
  )
}

export default SearchBrf
